<template>
  <div class="marketplace">
    <img
      src="https://cdn.bubbleplan.cn/static/marketplace/1413a69cacb7ba1c1bc1348e58696642.png"
      alt=""
      class="marketplace-but"
    />
    <div
      class="marketplace-complete flex items-center flex-between"
      @click="individualAccount()"
    >
      <div class="marketplace-complete-user flex items-center">
        <img
          :src="
            userInfo
              ? userInfo.headImg
              : 'https://cdn.bubbleplan.cn/m_bubble/mine/user.png'
          "
          alt=""
          class="marketplace-complete-user-img"
        />
        <div class="marketplace-complete-user-name">个人账户</div>
      </div>
      <div class="marketplace-complete-button">去看看</div>
    </div>
    <div class="marketplace-title flex items-center">
      <img
        src="https://cdn.bubbleplan.cn/static/marketplace/Group10390.png"
        alt=""
        class="marketplace-title-img"
      />
    </div>
    <div class="marketplace-type flex">
      <div
        v-for="(item, index) in typeList"
        :key="index"
        class="marketplace-type-li"
        @click="typeButton(item)"
      >
        <div class="marketplace-type-li-title">{{ item.title }}</div>
        <div class="flex" style="justify-content: center">
          <img :src="item.img" alt="" class="marketplace-type-li-img" />
        </div>
      </div>
    </div>
    <el-dialog
      title=""
      :visible.sync="notyetShow"
      width="30%"
      :show-close="false"
    >
      <div class="notyet">
        <img
          src="https://cdn.bubbleplan.cn/m_bubble/mine/notyetClose.png"
          mode=""
          class="notyet-img"
          @click="notyetShow = !notyetShow"
        />
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      notyetShow: false,
      typeList: [
        {
          // img: "https://cdn.bubbleplan.cn/static/marketplace/Group9735.png",
          url: "/marketplace/chipCrystal/chipCrystal",
          name: "TOKEN",
          title: "芯片/晶体",
          img: "https://cdn.bubbleplan.cn/commodity/chip.png",
        },
        {
          // img: "https://cdn.bubbleplan.cn/static/marketplace/Group9732.png",
          url: "/marketplace/property/property",
          name: "道具类",
          title: "道具",
          img: "https://cdn.bubbleplan.cn/m_bubble/commodity/property.png",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/commodity/skin.png",
          url: "/marketplace/skin/skin",
          name: "皮肤",
          title: "皮肤",
        },
        {
          img: "https://cdn.bubbleplan.cn/m_bubble/commodity/equip.png",
          url: "",
          name: "装备",
          title: "装备",
        },
      ],
      typeIndex: 0,
      userInfo: {},
      completeType: [],
    };
  },
  mounted() {
    this.userInfo = JSON.parse(localStorage.getItem("user_info"));
    this.getTypeList();
  },
  methods: {
    individualAccount() {
      if (!this.userInfo) {
        this.$message({
          message: "请先登录",
          type: "warning",
        });
        return;
      }
      this.$router.push("/marketplace/my/index");
    },
    //获取分类
    async getTypeList() {
      const { data, code } = await this.$ajax.getTypeList({
        isWantBuy: 100,
      });
      this.completeType = data;
    },
    typeButton(item) {
      if (this.completeType.length > 0) {
        this.completeType.forEach((l, i) => {
          if (l.name == item.name) {
            if (item.url) {
              let data = JSON.stringify(l);
              console.log(data);
              this.$router.push({
                path: item.url,
                query: { data },
              });
            } else {
              this.notyetShow = true;
            }
          } else {
          }
        });
        return;
      } else {
        this.notyetShow = true;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.marketplace::-webkit-scrollbar {
  width: 0px;
  height: 0px;
}
.marketplace {
  width: 100%;
  height: calc(100vh - 94px);
  background: url("https://cdn.bubbleplan.cn/static/marketplace/consignmentsales.png")
    no-repeat;
  background-size: 100% 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: center;
  overflow: auto;
  // position: relative;
  &-but {
    position: absolute;
    right: 20px;
    bottom: 0;
  }
  &-complete:hover {
    cursor: pointer;
  }
  &-complete {
    width: 1089px;
    height: 152px;
    background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10563.png")
      no-repeat;
    background-size: 100% 100%;
    padding: 16px 65px;

    &-user {
      &-img {
        width: 107px;
        height: 107px;
        margin-right: 21px;
      }
      &-name {
        font-family: "TsangerYuMo";
        color: #ffffff;
        font-size: 27px;
        font-weight: bold;
      }
    }
    &-button {
      width: 159px;
      height: 66px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Vector803.png")
        no-repeat;
      background-size: 100% 100%;
      color: #ffffff;
      font-family: "qiantuhouheiti";
      text-align: center;
      font-size: 28px;
      line-height: 56px;
    }
  }
  &-title {
    margin-top: 40px;
    margin-bottom: 20px;
    &-img {
      width: 212px;
      height: 51px;
    }
  }
  &-type {
    width: 100%;
    padding: 0 360px;
    flex-wrap: wrap;
    justify-content: space-between;

    &-li:hover {
      cursor: pointer;
    }

    &-li {
      width: 540px;
      height: 297px;
      background: url("https://cdn.bubbleplan.cn/static/marketplace/Group10547.png")
        no-repeat;
      background-size: 100% 100%;
      margin-bottom: 30px;
      position: relative;
      padding: 38px 54px;
      &-title {
        color: #fef5de;
        font-family: "qiantuhouheiti";
        font-size: 30px;
        letter-spacing: -0.9px;
        text-shadow: -2px -2px 0 #341030, 2px -2px 0 #341030, -2px 2px 0 #341030,
          2px 2px 0 #341030;
        /* 模拟描边效果 */
      }
      &-img {
        width: 191px;
        height: 191px;
      }
    }
  }
  :deep(.el-dialog__header) {
    padding: 0;
  }
  :deep(.el-dialog__body) {
    padding: 0;
  }
  :deep(.el-dialog) {
    margin-top: 36vh !important;
    background: transparent !important;
  }

  .notyet {
    width: 436px;
    height: 281px;
    background: url("https://cdn.bubbleplan.cn/m_bubble/mine/notyet.png")
      no-repeat;
    background-size: 100% 100%;
    &-img:hover {
      cursor: pointer;
    }
    &-img {
      width: 54px;
      height: 53px;
      margin-top: 40px;
      margin-left: 383px;
    }
  }
}
</style>